import React, { lazy } from "react";
import {Route, Routes} from "react-router";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../state/store";
const Main = lazy(() => import('../pages/main/Main'));
const Quests = lazy(() => import('../pages/quests/Quests'));
const Leaderboard = lazy(() => import('../pages/leaderboard/Leaderboard'));
const Wallet = lazy(() => import('../pages/wallet/Wallet'));
const Rewards = lazy(() => import('../pages/rewards/Rewards'));
const Referals = lazy(() => import('../pages/referals/Referals'));
const NotFoundPage = lazy(() => import('../pages/notFound/NotFound'));
const LoadingPage = lazy(() => import('../pages/loading/loadingPage'));

export const DefaultLayout = () => {
    const isInitialized = useSelector<AppRootStateType, boolean>(state => state.app.isInitialized)
    return (
        <>
            {
                isInitialized
                    ?
                    <Routes>
                        <Route path="/" element={<Main/>}/>
                        <Route path="/quests" element={<Quests/>}/>
                        <Route path="/ranks" element={<Leaderboard/>}/>
                        <Route path="/wallet" element={<Wallet/>}/>
                        <Route path="/rewards" element={<Rewards/>}/>
                        <Route path="/referrals" element={<Referals/>}/>
                        <Route path="/*" element={<NotFoundPage/>}/>
                    </Routes>
                    : <LoadingPage/>
            }
        </>
    )
}