import {AxiosResponse} from "axios";
import {$api} from "../state";
import {ReferralDataType} from "../models/ReferalsResponse";
import {getToken} from "../../utils/Utils";



export const ReferralsService = {
    fetchReferrals(params: string): Promise<AxiosResponse<ReferralDataType>> {
        return $api.get('/referrals', {
            headers: {Authorization: `Bearer tg-${getToken()}`}
        })
    },
}

