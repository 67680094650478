import {AxiosResponse} from "axios";
import {$api} from "../state";
import {ChestsDataType} from "../reducers/chests-reducer";
import {DefiniteChestType} from "../models/ChestsResponse";
import {getToken} from "../../utils/Utils";

export type OpenDefiniteChestParamsType = {
    chest_number: number
}
export type DoubleWinParamsType = {
    id: number
}
export type TryAgainResponseType = {
    id: number
    reward: {
        type: string
        name: string
        amount: number
        doublable: boolean
    },
    try_again: boolean
}

export const ChestsService = {
    fetchChestsData(): Promise<AxiosResponse<ChestsDataType>> {
        return $api.get('/init', {
            headers: {Authorization: `Bearer tg-${getToken()}`}
        })
    },
    fetchDefiniteChest(data: OpenDefiniteChestParamsType): Promise<AxiosResponse<DefiniteChestType>> {
        return $api.post('/open', data, {
            headers: {Authorization: `Bearer tg-${getToken()}`}
        })
    },
    doubleChestWin(data: DoubleWinParamsType): Promise<AxiosResponse<{ id: number }>> {
        return $api.post('/double', data, {
            headers: {Authorization: `Bearer tg-${getToken()}`}
        })
    },
    tryAgainChestWin(data: DoubleWinParamsType): Promise<AxiosResponse<DefiniteChestType>> {
        return $api.post('/try_again', data, {
            headers: {Authorization: `Bearer tg-${getToken()}`}
        })
    },
}

