import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {RanksDataType, RanksService} from "../services/ranks-service";
import {AxiosError} from "axios";
import {handleResponseError} from "../../utils/error-utils";
import {ChestsService, OpenDefiniteChestParamsType} from "../services/chests-service";
import {DefiniteChestType} from "../models/ChestsResponse";
import {chestsActions} from "./chests-reducer";

type  InitialStateType = {
    ranks: RanksDataType | null
};

const initialState: InitialStateType = {
    ranks: null
}

const slice = createSlice({
    name: "ranks",
    initialState: initialState,
    reducers: {
        setRanks: (state: InitialStateType, action: PayloadAction<RanksDataType>) => {
            console.log(action.payload)
            state.ranks = action.payload
            console.log(state.ranks)
        },
    },
});
export const ranksReducer = slice.reducer;
export const ranksActions = slice.actions;


// export const fetchRanksDataTC = (): AppThunk =>
//     (dispatch) => {
//         RanksService.fetchRanksData().then((res) => {
//             dispatch(ranksActions.setRanks(res.data));
//         })
//             .catch(err => {
//                 handleResponseError(err, dispatch)
//     });
// };
export const fetchRanksDataTC = (): AppThunk<Promise<RanksDataType | void>> =>
    async (dispatch) => {
        try {
            const res = await RanksService.fetchRanksData();
            dispatch(ranksActions.setRanks(res.data));
            return res.data
        } catch (err) {
            if (err instanceof AxiosError)
                handleResponseError(err, dispatch)
        }
    };


