import {AnyAction, combineReducers} from "redux";
import {configureStore, ThunkAction, ThunkDispatch} from "@reduxjs/toolkit";
import {chestsReducer} from "./reducers/chests-reducer";
import {modalsReducer} from "./reducers/modals-reducer";
import {appReducer} from "./reducers/app-reducer";
import {ranksReducer} from "./reducers/ranks-reducer";
import {rewardsReducer} from "./reducers/reward-reducer";
import {questsReducer} from "./reducers/quests-reducer";
import {referralsReducer} from "./reducers/referals-reducer";

export const rootReducer = combineReducers({
    chests: chestsReducer,
    modals: modalsReducer,
    app: appReducer,
    ranks: ranksReducer,
    reward: rewardsReducer,
    quests: questsReducer,
    referrals: referralsReducer,
})
export type AppRootStateType = ReturnType<typeof rootReducer>

export const store = configureStore({
    reducer: rootReducer
})

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppRootStateType, unknown, AnyAction>;

export type AppDispatch = ThunkDispatch<AppRootStateType, unknown, AnyAction>;

// @ts-ignore
window.store = store