import {AxiosResponse} from "axios";
import {$api} from "../state";
import {getToken} from "../../utils/Utils";

export type TokenValueType = {
    rank: number
    name: string
    amount: number
    you?: boolean
}

export type RanksDataType = {
    ton: TokenValueType[]
    ltr: TokenValueType[]
    ton_day: TokenValueType[]
    ltr_day: TokenValueType[]
    ton_week: TokenValueType[]
    ltr_week: TokenValueType[]
    update_time: string
}

export const RanksService = {
    fetchRanksData(): Promise<AxiosResponse<RanksDataType>> {
        return $api.get('/ranks', {
            headers: {Authorization: `Bearer tg-${getToken()}`}
        })
    },
}

