import {AxiosError} from "axios";
import {modalsActions} from "../state/reducers/modals-reducer";
import {setChestsDataTC} from "../state/reducers/chests-reducer";
import {AppDispatch} from "../state/store";

export const handleResponseError = (error: AxiosError, dispatch: AppDispatch) => {
    if (error.response) {
        if (error.response.status === 429) {
            console.log(error.response);
            dispatch(modalsActions.changeStateOfAlertModal({
                title: '',
                subTitle: 'Request limit exceeded',
                state: true,
                errorState:true
            }));
        }
        if (error.response.status === 409) {
            console.log(error.response);
            dispatch(modalsActions.changeStateOfAlertModal({
                title: 'OPENED',
                subTitle: 'This chest has already been opened, try another one',
                state: true,
                errorState:true
            }));
        }
    }
}