import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type  InitialStateType = {
    isInitialized: boolean
};

const initialState: InitialStateType = {
    isInitialized: false
}

const slice = createSlice({
    name: "app",
    initialState: initialState,
    reducers: {
        initializeStateOfApp: (state: InitialStateType, action: PayloadAction<boolean>) => {
            state.isInitialized = action.payload
        },
    },
});
export const appReducer = slice.reducer;
export const appActions = slice.actions;


