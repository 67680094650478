import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type AlertModalType = {
    title:string
    subTitle:string
    state:boolean
    errorState?:boolean
}
export type BasicModalType = {
    title:string
    state:boolean
    subTitle:string
}

type  InitialStateType = {
    winState: boolean
    lossState: boolean
    alertModal:AlertModalType
    rules: boolean
    basic: BasicModalType
};

const initialState: InitialStateType = {
    winState: false,
    lossState: false,
    alertModal: {
        title:'',
        subTitle:'',
        state:false
    },
    rules: false,
    basic: {
        title:'',
        subTitle:'',
        state:false
    }
}

const slice = createSlice({
    name: "modals",
    initialState: initialState,
    reducers: {
        changeWinState: (state: InitialStateType, action: PayloadAction<boolean>) => {
            state.winState = action.payload
        },
        changeLossState: (state: InitialStateType, action: PayloadAction<boolean>) => {
            state.lossState = action.payload
        },
        changeStateOfAlertModal: (state: InitialStateType, action: PayloadAction<AlertModalType>) => {
            state.alertModal = action.payload
        },
        changeStateOfBasicModal: (state: InitialStateType, action: PayloadAction<BasicModalType>) => {
            state.basic = action.payload
        },
        changeStateOfRules: (state: InitialStateType, action: PayloadAction<boolean>) => {
            state.rules = action.payload
        }
    },
});
export const modalsReducer = slice.reducer;
export const modalsActions = slice.actions;


