import {AxiosResponse} from "axios";
import {$api} from "../state";
import {getToken} from "../../utils/Utils";

export type RewardValueType = {
    n: number
    number_of_chest: number
    token: string
    amount: number
    chance: string
}

export type RewardDataType = {
    rewards: RewardValueType[]
    update_time: string
}

export const RewardsService = {
    fetchRewardsData(): Promise<AxiosResponse<RewardDataType>> {
        return $api.get('/rewards', {
            headers: {Authorization: `Bearer tg-${getToken()}`}
        })
    },
}

