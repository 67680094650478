import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {AxiosError} from "axios";
import {QuestService} from "../services/quest-service";
import {AllQuestDataType, QuestType} from "../models/QuetsResponse";
import {handleResponseError} from "../../utils/error-utils";


type  InitialStateType = {
    daily_quests: QuestType[] | null
    basic_quests: QuestType[] | null
    daily_quests_done: QuestType[] | null
    basic_quests_done: QuestType[] | null
}

const initialState: InitialStateType = {
    daily_quests: null,
    basic_quests: null,
    daily_quests_done: null,
    basic_quests_done: null
}
export type claimDailyQuestype = {
    quest: QuestType
    quest_id: string
}

const slice = createSlice({
    name: "quests",
    initialState: initialState,
    reducers: {
        setDailyQuests: (state: InitialStateType, action: PayloadAction<QuestType[]>) => {
            state.daily_quests = action.payload.filter(quest => quest.status !== 'done')
        },
        setDoneDailyQuests: (state: InitialStateType, action: PayloadAction<QuestType[]>) => {
            if (state.daily_quests !== null) {
                state.daily_quests_done = action.payload.filter(quest => quest.status === 'done')
            }
        },
        setBasicQuests: (state: InitialStateType, action: PayloadAction<QuestType[]>) => {
            state.basic_quests = action.payload.filter(quest => quest.status !== 'done')
        },
        setDoneBasicQuests: (state: InitialStateType, action: PayloadAction<QuestType[]>) => {
            if (state.basic_quests !== null) {
                state.basic_quests_done = action.payload.filter(quest => quest.status === 'done')
            }
        },
        spliceDailyQuests: (state: InitialStateType, action: PayloadAction<QuestType>) => {
            if (state.daily_quests !== null) {
                state.daily_quests = state.daily_quests && state.daily_quests.filter(quest => quest.quest_id !== action.payload.quest_id)
            }
        },
        spliceBasicQuests: (state: InitialStateType, action: PayloadAction<QuestType>) => {
            if (state.basic_quests !== null) {
                state.basic_quests = state.basic_quests && state.basic_quests.filter(quest => quest.quest_id !== action.payload.quest_id)
            }
        },
        claimDailyQuest: (state: InitialStateType, action: PayloadAction<claimDailyQuestype>) => {
            state.daily_quests && state.daily_quests.forEach(st => {
                if (st.quest_id === action.payload.quest_id) {
                    if (st.status === 'claim') {
                        if (state.daily_quests_done !== null) {
                            st.status = action.payload.quest.status
                            state.daily_quests_done.push(st)
                        }
                    }
                } else {
                    return st
                }
            })
        },
        claimBasicQuest: (state: InitialStateType, action: PayloadAction<claimDailyQuestype>) => {
            state.basic_quests && state.basic_quests.forEach(st => {
                if (st.quest_id === action.payload.quest_id) {
                    if (st.status === 'claim') {
                        if (state.basic_quests_done !== null) {
                            st.status = action.payload.quest.status
                            state.basic_quests_done.push(st)
                        }
                    }
                } else {
                    return st
                }
            })
        },
    },
});
export const questsReducer = slice.reducer;
export const questsActions = slice.actions;

export const claimDailyQuestTC = (quest_id: string): AppThunk<Promise<QuestType | void>> =>
    async (dispatch) => {
        try {
            const res = await QuestService.claimQuest(quest_id);
            // dispatch(questsActions.claimDailyQuest({quest: res.daily_quests, quest_id: quest_id}))
            if (res.data.status === 'done') {
                return res.data
            } else {
                console.log('status not done')
            }
        } catch (err) {
            if (err instanceof AxiosError)
                handleResponseError(err, dispatch)
        }
    };


export const setQuestsDataTC = (): AppThunk<AllQuestDataType | void> =>
    (dispatch) => {
        QuestService.fetchQuests().then((res) => {
            dispatch(questsActions.setDailyQuests(res.data.daily_quests));
            dispatch(questsActions.setDoneDailyQuests(res.data.daily_quests));

            dispatch(questsActions.setBasicQuests(res.data.basic_quests));
            dispatch(questsActions.setDoneBasicQuests(res.data.basic_quests));
        })
            .catch(err => {
                handleResponseError(err, dispatch)
            });
    };

