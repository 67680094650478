import {AxiosResponse} from "axios";
import {AllQuestDataType, QuestType} from "../models/QuetsResponse";
import {$api} from "../state";
import {getToken} from "../../utils/Utils";


export const QuestService = {
    fetchQuests(): Promise<AxiosResponse<AllQuestDataType>> {
        return $api.get('/quest_info', {
            headers: {Authorization: `Bearer tg-${getToken()}`}
        })
    },
    claimQuest(quest_id: string): Promise<AxiosResponse<QuestType>> {
        return $api.post('/quest',
            {quest_id: quest_id},
            {headers: {Authorization: `Bearer tg-${getToken()}`}})
    },
}

