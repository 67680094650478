import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {RewardDataType, RewardsService} from "../services/rewards-service";
import {AxiosError} from "axios";
import {handleResponseError} from "../../utils/error-utils";

type  InitialStateType = {
    rewards: RewardDataType | null
};

const initialState: InitialStateType = {
    rewards: null
}

const slice = createSlice({
    name: "rewards",
    initialState: initialState,
    reducers: {
        setRewards: (state: InitialStateType, action: PayloadAction<RewardDataType>) => {
            state.rewards = action.payload
        },
    },
});
export const rewardsReducer = slice.reducer;
export const rewardsActions = slice.actions;


export const fetchRewardsDataTC = (): AppThunk =>
    (dispatch) => {
        RewardsService.fetchRewardsData().then((res) => {
            dispatch(rewardsActions.setRewards(res.data));
        })
            .catch(err => {
                handleResponseError(err, dispatch)
            });
    };



