export function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
}

export function formatTime(date: Date | string | number) {
    const perfDate = new Date(date)

    return [
        padTo2Digits(perfDate.getHours()),
        padTo2Digits(perfDate.getMinutes()),
        padTo2Digits(perfDate.getSeconds()),
    ].join(':');
}

const SECONDS_PER_DAY = 86400;
const HOURS_PER_DAY = 24;

export const secondsToHms = (seconds: number): string => {
    const days = Math.floor(seconds / SECONDS_PER_DAY);
    const remainderSeconds = seconds % SECONDS_PER_DAY;
    const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
    return hms.replace(/^(\d+)/, h => `${Number(h) + days * HOURS_PER_DAY}`.padStart(2, '0'));
}


export function numberWithSpaces(x: number | string) {
    let y = x.toString()
    var parts = y.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}

export function numberWithSpaces2(x: number | string) {
    let y = x.toString()
    var parts = y.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "");
    return parts.join(".");
}

export const prettifyAmount = (amount: number) => numberWithSpaces((amount / 1000))

export const copyLink = async (title: string) => {
    await navigator.clipboard.writeText(title);
    setTimeout(() => {
        console.log('copied')
    }, 300)
    console.log('Uncopied')
}
export const getToken = () => {
    // const token = window.Telegram?.WebApp.initData || 'query_id=AAEx0vInAAAAADHS8idd1_gg&user=%7B%22id%22%3A670224945%2C%22first_name%22%3A%22Alina%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22strckan%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1723551248&hash=921063546a6e8bf3e157e21936a2e3a8954febcc6a2c113e65e34a3b50a51e09'
    const token = window.Telegram?.WebApp.initData || ''
    return token
}