import React, {FC, useEffect} from "react";
import '../App.css';
import {Navigate} from "react-router-dom";
import {useAppDispatch} from "../state/hooks/useAppDispatch";
import {chestsActions} from "../state/reducers/chests-reducer";
import {getToken} from "../utils/Utils";

interface Props {
    children: JSX.Element
}

export const ProtectedRoute: FC<Props> = ({children}) => {
    const dispatch = useAppDispatch();
    const key = getToken()

    useEffect(() => {
        if(key.length){
            dispatch(chestsActions.setInitData())
        }
    }, []);

    return (
        <div className={'DefaultLayoutWrapper'}>
            {
                key.length ? (
                    children
                ) : (
                    <Navigate to="not_found" replace/>
                )
            }
        </div>
    )
}