import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {
    ChestsService,
    DoubleWinParamsType,
    OpenDefiniteChestParamsType,
    TryAgainResponseType
} from "../services/chests-service";
import {DefiniteChestType, OpenChestType, RewardNameType} from "../models/ChestsResponse";
import {getToken} from "../../utils/Utils";
import {handleResponseError} from "../../utils/error-utils";
import {AxiosError} from "axios";

export type ChestsDataType = {
    energy: number | null
    time_to_next: number
    balance_ton: number
    balance_ltr: number
    opened_chests: number[]
}

type  InitialStateType = {
    init_data: string
    chests_data: ChestsDataType | null
    opened_chests: OpenChestType[]
    opendefiniteChest: DefiniteChestType
};
export type ChestsWinType = {
    type: RewardNameType
    value: number
}
const initialState: InitialStateType = {
    init_data: '',
    chests_data: null,
    opened_chests: [
        {index: 1, isOpen: false, shine: false, possibilityOfOpen: true,},
        {index: 2, isOpen: false, shine: false, possibilityOfOpen: true,},
        {index: 3, isOpen: false, shine: false, possibilityOfOpen: true,},
        {index: 4, isOpen: false, shine: false, possibilityOfOpen: true,},
        {index: 5, isOpen: false, shine: false, possibilityOfOpen: true,},
        {index: 6, isOpen: false, shine: false, possibilityOfOpen: true,},
        {index: 7, isOpen: false, shine: false, possibilityOfOpen: true,},
        {index: 8, isOpen: false, shine: false, possibilityOfOpen: true,},
        {index: 9, isOpen: false, shine: false, possibilityOfOpen: true,},
        {index: 10, isOpen: false, shine: false, possibilityOfOpen: true,},
        {index: 11, isOpen: false, shine: false, possibilityOfOpen: true,},
        {index: 12, isOpen: false, shine: false, possibilityOfOpen: true,},
    ],
    opendefiniteChest: {
        id: Number(localStorage.getItem('id')),
        reward: null,
        try_again: false
    }
}

const slice = createSlice({
    name: "chests",
    initialState: initialState,
    reducers: {
        setInitData: (state: InitialStateType, action: PayloadAction) => {
            state.init_data = getToken()
        },
        setChestsData: (state: InitialStateType, action: PayloadAction<ChestsDataType>) => {
            state.chests_data = action.payload
            localStorage.setItem('tonValue', JSON.stringify(state.chests_data.balance_ton))
            localStorage.setItem('ltrValue', JSON.stringify(state.chests_data.balance_ltr))
        },
        changeSecondsValue: (state: InitialStateType, action: PayloadAction) => {
            if (state.chests_data) {
                state.chests_data.time_to_next = 0
            }
        },
        setOpenChests: (state: InitialStateType, action: PayloadAction<ChestsDataType>) => {
            state.opened_chests = state.opened_chests.map((chest, index) => {
                return {...chest, isOpen: action.payload.opened_chests.includes(chest.index), shine: false}
            })
        },
        changeStateOfPossibilityOfOpen: (state: InitialStateType, action: PayloadAction<boolean>) => {
            state.opened_chests = state.opened_chests.map((chest, index) => {
                return {...chest, possibilityOfOpen: action.payload}
            })
        },
        changeOpenState: (state: InitialStateType, action: PayloadAction<number>) => {
            state.opened_chests.forEach((chest: OpenChestType) => {
                if (chest.index === action.payload) {
                    chest.isOpen = true
                }
            })
        },
        changeShineTrueState: (state: InitialStateType, action: PayloadAction<{ id: number }>) => {
            state.opened_chests.forEach((chest: OpenChestType) => {
                if (chest.index === action.payload.id) {
                    chest.shine = true
                }
            })
            // debugger
        },
        changeShineFalseState: (state: InitialStateType, action: PayloadAction<{ id: number }>) => {
            state.opened_chests.forEach((chest: OpenChestType) => {
                if (chest.index === action.payload.id) {
                    chest.shine = false
                }
            })
            // debugger
        },
        openDefiniteChest: (state: InitialStateType, action: PayloadAction<DefiniteChestType>) => {
            state.opendefiniteChest = action.payload
            localStorage.setItem('id', JSON.stringify(action.payload.id))
        },
        changeBalance: (state: InitialStateType, action: PayloadAction<ChestsWinType>) => {
            if (state.chests_data) {
                const tonBalance = state.chests_data.balance_ton;
                const ltrBalance = state.chests_data.balance_ltr;

                if (action.payload.type === 'TON') {
                    state.chests_data.balance_ton = tonBalance + action.payload.value
                    localStorage.setItem('tonValue', JSON.stringify(state.chests_data.balance_ton))
                } else if (action.payload.type === 'LTR') {
                    state.chests_data.balance_ltr = ltrBalance + action.payload.value
                    localStorage.setItem('ltrValue', JSON.stringify(state.chests_data.balance_ltr))
                }
            }
        },
        decreaseEnergyCount: (state: InitialStateType, action: PayloadAction) => {
            if (state.chests_data?.energy) {
                state.chests_data.energy = state.chests_data.energy - 1
            }
        },
        increaseEnergyCount: (state: InitialStateType, action: PayloadAction) => {
            if (state.chests_data && state.chests_data?.energy !== null && state.chests_data.energy >= 0) {
                state.chests_data.energy = state.chests_data.energy + 1
            }
        },
    },
});
export const chestsReducer = slice.reducer;
export const chestsActions = slice.actions;


export const setChestsDataTC = (): AppThunk =>
    (dispatch) => {
        ChestsService.fetchChestsData()
            .then((res) => {
                dispatch(chestsActions.setChestsData(res.data));
                dispatch(chestsActions.setOpenChests(res.data));
            })
            .catch(err => {
                handleResponseError(err, dispatch)
            });
    };

export const openDefiniteChestTC = (data: OpenDefiniteChestParamsType): AppThunk<Promise<DefiniteChestType | void>> =>
    async (dispatch) => {
        try {
            const res = await ChestsService.fetchDefiniteChest(data);
            dispatch(chestsActions.openDefiniteChest(res.data))
            dispatch(chestsActions.changeOpenState(data.chest_number))
            return res.data
        } catch (err) {
            if (err instanceof AxiosError)
                handleResponseError(err, dispatch)
        }
    };

export const doubleChestWinTC = (params: DoubleWinParamsType): AppThunk<Promise<{ id: number } | void>> =>
    async (dispatch) => {
        try {
            const res = await ChestsService.doubleChestWin(params);
            return res.data
        } catch (err) {
            if (err instanceof AxiosError)
                handleResponseError(err, dispatch)
        }
    };

export const tryAgainToWinTC = (params: DoubleWinParamsType): AppThunk<Promise<DefiniteChestType | void>> =>
    async (dispatch) => {
        try {
            const res = await ChestsService.tryAgainChestWin(params);
            // dispatch(chestsActions.openDefiniteChest(res.data))
            return res.data
        } catch (err) {
            if (err instanceof AxiosError)
                handleResponseError(err, dispatch)
        }
    };